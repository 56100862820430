<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <span>标签名称:</span>
        <el-input v-model="name" placeholder="请输入标签名称" class="handle-input mr10 w100"></el-input>
        <span>渠道:</span>
        <el-select v-model="clientId" placeholder="请选择" class="handle-select mr10">
          <el-option
            :key="item.id"
            v-for="(item) in channelDatas"
            :label="item.description"
            :value="item.clientId"
          ></el-option>
        </el-select>
        <span>是否启用:</span>
        <el-select v-model="isEnable" placeholder="请选择" class="handle-select mr10 w80">
          <el-option key label="请选择" value></el-option>
          <el-option key="1" label="启用" value="true"></el-option>
          <el-option key="0" label="禁用" value="false"></el-option>
        </el-select>

        <el-button type="primary ml10" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <div class="btns">
        <el-button type="primary ml10" icon="el-icon-plus" @click="addCate(1)">添加标签</el-button>
        <el-button type="primary ml10" icon="el-icon-circle-check" @click="changeStatus(1)">批量启用</el-button>
        <el-button type="primary ml10" icon="el-icon-circle-close" @click="changeStatus(0)">批量禁用</el-button>
      </div>
      <el-table
        :data="list"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="标签Id" width="120" align="center"></el-table-column>
        <el-table-column prop="name" label="标签名称" align="center"></el-table-column>
        <el-table-column prop="sort" width="120" label="排序" align="center"></el-table-column>
        <el-table-column prop="isEnable" label="状态" align="center">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.isEnable">启用</el-tag>
            <el-tag type="danger" v-else>禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="parentName" label="渠道" align="center">
          <template #default="scope">{{clientIdFilter(scope.row.clientId)}}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              type="text"
              icon="el-icon-error"
              v-if="scope.row.isEnable"
              class="red"
              @click="DisableRow(scope.$index, scope.row)"
            >禁用</el-button>
            <el-button
              type="text"
              v-else
              icon="el-icon-success"
              @click="DisableRow(scope.$index, scope.row)"
            >启用</el-button>
            <!-- 
            <el-button type="text"
                       icon="el-icon-delete"
                       class="red"
            @click="delRow(scope.$index, scope.row)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="totalSize"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog :title="dialogTitle" v-model="editVisible" width="30%">
      <el-form ref="form" :model="rowData" label-width="100px">
        <el-form-item label="标签名称:">
          <el-input v-model="rowData.name"  placeholder="请输入标签名称"></el-input>
        </el-form-item>
        <el-form-item label="排序:">
          <el-input v-model.number="rowData.sort" type="number" placeholder="数字越小，越靠前"></el-input>
        </el-form-item>
        <el-form-item label="渠道:">
          <el-select v-model="rowData.clientId" placeholder="请选择" class="handle-select mr10">
            <el-option
              :key="item.id"
              v-for="(item) in channelDatas "
              :label="item.description"
              :value="item.clientId"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="check">
          <el-form-item label="是否启用">
            <el-checkbox v-model="rowData.isEnable">启用</el-checkbox>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">保 存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  seoLabelUpdate,
  seoLabelBatch,
  clientConfig,
  seoLabelList,
  seoLabelOption
} from "../../api/index";
export default {
  name: "label",
  data() {
    return {
      channelDatas: [], //渠道
      clientId: "",
      pageNum: 1, // 当前分页
      pageSize: 20, //
      totalSize: 0,
      name: "",
      isEnable: "",
      category: "", // 分类
      rowData: null, // 行数据
      editVisible: false,
      list: [],
      form: {},
      idx: -1,
      id: -1,
      tipsTxt: "",
      ids: [], // 选中id
      dialogTitle: "添加标签"
    };
  },
  created() {
    this.channelArray(); // 渠道
    this.getData();
  },
  methods: {
    clientIdFilter(val) {
      let str = "";
      this.channelDatas.forEach(element => {
        if (element.clientId == val) {
          str = element.description;
        }
      });
      return str + " - " + val;
    },
    channelArray() {
      clientConfig({
        type:'SEO'
      }).then(res => {
        if (res.code == 0) {
          this.channelDatas = res.data;
          this.channelDatas.unshift({
            description: "全部",
            clientId: ""
          });
        }
        console.log("res", res);
      });
    },
    // 获取子分类
    //禁用
    DisableRow(i, row) {
      this.$confirm("将禁用该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          seoLabelOption(row.id).then(res => {
            console.log("res", res);
            let str = row.isEnable ? "禁用" : "启用";
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: str + "成功!"
              });
              this.getData();
            } else {
              this.$message({
                type: "error",
                message: str + "失败!"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消禁用"
          });
        });
    },

    //删除
    delRow() {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    // 获取 数据
    getData() {
      let params = {
        name: this.name, //数字则查id，文字则查名称，或者标题
        clientId: this.clientId, // 行业
        pageIndex: this.pageNum,
        pageSize: this.pageSize,
        isEnable: this.isEnable == 'false' ? false : true
      };
      if (this.isEnable == "") {
        delete params.isEnable;
      }
      seoLabelList(params).then(res => {
        console.log(res);
        if (res.code === 0) {
          this.list = res.data.data;
          this.totalSize = res.data.total;
        } else {
          this.$message({
            message: "列表获取失败",
            type: "error"
          });
        }
      });
    },
    //搜索按钮
    handleSearch() {
      this.pageNum = 1;
      this.getData();
    },

    // 多选操作
    handleSelectionChange(val) {
      let idList = val.map(item => {
        return item.id;
      });
      this.ids = idList;
      console.log(
        idList,
        "multipleSelectionmultipleSelectionmultipleSelection"
      );
    },
    // 添加分类
    addCate(flag) {
      this.rowData = {
        id: "",
        name: "",
        sort: "",
        clientId: "",
        isEnable: ""
      };
      if (flag == 1) {
        this.dialogTitle = "添加标签";
      }
      this.editVisible = true;
    },
    checkIds() {
      if (this.ids.length <= 0) {
        this.$message({
          message: "未选中数据",
          type: "error"
        });
        return false;
      }
    },
    // 批量启用禁用删除 ：-1删除（暂不支持），1启用，0禁用
    changeStatus(val) {
      this.checkIds();
      if (this.ids.length <= 0) {
        return false;
      }
      let ids = this.ids;
      let params = {
        isEnable: val ? true : false,
        ids: ids
      };

      if (val == 1) {
        this.tipsTxt = "启用";
      } else if (val == 0) {
        this.tipsTxt = "禁用";
      }
      seoLabelBatch(params).then(res => {
        if (res.code == 0) {
          this.$message({
            message: this.tipsTxt + res.message,
            type: "success"
          });
          this.getData();
        } else {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    },

    // 编辑操作
    handleEdit(index, row) {
      console.log(row, 11);
      this.dialogTitle = "修改标签";
      this.rowData = Object.assign({}, row);
      this.idx = index;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      let params = {
        name: this.rowData.name,
        sort: this.rowData.sort,
        clientId: this.rowData.clientId,
        isEnable: this.rowData.isEnable
      };
      if (this.rowData.id) {
        params.id = this.rowData.id;
      }
      if (this.rowData.name == "") {
        this.$message({
          message: "请输入标签名称",
          type: "error"
        });
        return false;
      }
      if (this.rowData.clientId == "") {
        this.$message({
          message: "请选择渠道",
          type: "error"
        });
        return false;
      }

      seoLabelUpdate(params).then(res => {
        if (res.code != 0) {
          this.$message({
            message: res.message,
            type: "error"
          });
        } else {
          this.getData();
          this.editVisible = false;
          this.$message({
            message: params.id ? "修改成功" : "添加成功",
            type: "success"
          });
        }
      });
    },
    // 分页导航
    handlePageChange(val) {
      this.pageNum = val;
      this.getData();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.w80 {
  width: 98px;
}
.ml10 {
  margin-left: 10px;
}
.btns {
  margin-bottom: 10px;
}
.check {
  display: flex;
}
</style>
